/**
 * HERO CAROUSEL
 * @description : Swiper for Hero Carousel modules
 */

(function () {

    var swiperEl = document.querySelector('.js-related-resources-carousel__swiper');
    var slideEls = swiperEl.querySelectorAll('.js-related-resource-carousel__slide');

    if (swiperEl && slideEls.length > 1) {
        var swiper = new Swiper(swiperEl, {
            spaceBetween: 20,
            slidesPerView: 1.2,
            direction: 'horizontal',
            loop: false,
            autoplay: false,
            speed: 500,
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
                draggable: true,
                dragSize: 100
            },
            breakpoints: {
                [window.breakpoints['sm']]: {
                    spaceBetween: 20,
                    scrollbar: {
                        dragSize: 200
                    },
                    slidesPerView: 1.5,
                },
                [window.breakpoints['md']]: {
                    scrollbar: {
                        dragSize: 300
                    },
                    spaceBetween: 35,
                    slidesPerView: 2
                },
                [window.breakpoints['lg']]: {
                    scrollbar: {
                        dragSize: 400
                    },
                    spaceBetween: 40,
                    slidesPerView: 3
                },
                [window.breakpoints['xl']]: {
                    scrollbar: {
                        dragSize: 500
                    },
                    spaceBetween: 46,
                    slidesPerView: 4
                }
            },
        });

        swiper.on('slideChange', function () {
            // Load all lazyloading images straight away once the first slide has passed.
            var lazyLoadingMediaEls = swiperEl.querySelectorAll('[loading]');
            if (lazyLoadingMediaEls.length) {
                lazyLoadingMediaEls.forEach(el => {
                    el.removeAttribute('loading');
                });
            }
        });

    }

})();